// import { FaIdCard } from '@react-icons/all-files/fa/FaIdCard'
// import { FaSchool } from '@react-icons/all-files/fa/FaSchool'
// import { FaLaptopCode } from '@react-icons/all-files/fa/FaLaptopCode'
import AbcSchoolLogo from '../../assets/personal/education/AbcSchoolLogo.png'
import MvSchoolLogo from '../../assets/personal/education/MvSchoolLogo.png'
import KwSchoolLogo from '../../assets/personal/education/KwSchoolLogo.png'
import KkuSchoolLogo from '../../assets/personal/education/KkuSchoolLogo.png'
import NectecLogo from '../../assets/personal/company/NectecLogo.png'
import CubeSofttechLogo from '../../assets/personal/company/CubeSofttechLogo.png'
import AycapLogo from '../../assets/personal/company/AycapLogo.png'
import KrungthaiLogo from '../../assets/personal/company/KrungthaiLogo.png'
import InfinitasByKrungthaiLogo from '../../assets/personal/company/InfinitasByKrungthaiLogo.png'
import WedoByScgLogo from '../../assets/personal/company/WedoByScgLogo.png'
import GcpCloudArchCert from '../../assets/personal/CgpProCloudArchCert.png'

export default function AboutMe() {
    return (
        <div className="container mx-auto  text-[#ababab]">
            <div className="grid grid-cols-1 md:grid-cols-3">
                <div className='pt-10'>
                    {/* <FaIdCard className="m-auto" size="10vh" color="#bcbcbc" /> */}
                    <div>
                        <h2 className="text-5xl py-10 font-semibold text-white">Personal Info.</h2>
                        <div className='py-2 text-sm'>
                            <p><strong>Hi! I'm software developer.</strong></p>
                            <p><strong className='text-white'>Name : </strong>Satapol Chatpahol.</p>
                            <p><strong className='text-white'>Nickname : </strong>Film <strong className='text-white'>Age : </strong> 30 Years.</p>
                            <p><strong className='text-white'>Hometown : </strong>Chaiyaphum, Thailand.</p>
                            <p><strong className='text-white'>Live in : </strong>Samut Prakarn, Thailand.</p>
                            <p><strong className='text-white'>Work in : </strong>Bangkok, Thailand.</p>
                        </div>
                    </div>
                    <div className='pt-5'>
                        <h2 className="text-2xl text-white">Tech. Certificate</h2>
                        <div className='py-2'>
                            <a href="https://www.credential.net/7863ba7a-94de-4a5a-b128-6b5d60554040?key=2b68c5c0fb07d781d5aed7fadc8f9d9883a01e850e3fcc2bbcdf6f940bc35bea" target="_blank" rel="noreferrer">
                                <img
                                    className="m-auto py-2"
                                    width="50%"
                                    src={GcpCloudArchCert}
                                    alt="Google Cloud Platform Certificate"
                                /></a>
                            <p className='font-bold text-white'>Google Cloud Platform</p>
                            <p>Profressional Cloud Architect</p>
                        </div>
                    </div>
                </div>
                <div className='pt-10'>
                    {/* <FaSchool className="m-auto" size="10vh" color="#bcbcbc" /> */}
                    <h2 className="text-5xl py-10 font-semibold text-white">Education</h2>
                    <div>
                        <div className="inline-flex w-full p-2">
                            <div className="w-4/12 inline-flex">
                                <div className="m-auto rounded-full w-16 h-16 flex bg-white">
                                    <img
                                        className="m-auto pt-2"
                                        height="70%"
                                        width="70%"
                                        src={AbcSchoolLogo}
                                        alt="Anuban Chaiyaphum School"
                                    />
                                </div>
                            </div>
                            <div className="w-8/12 text-left text-sm">
                                <p className="font-bold text-white">Anuban Chaiyaphum School</p>
                                <p>Kindergarten-Elementary School</p>
                                <p>1996-2003</p>
                                <p>Chaiyaphum</p>
                            </div>
                        </div>
                        <div className="inline-flex w-full p-2">
                            <div className="w-4/12 inline-flex">
                                <div className="m-auto rounded-full w-16 h-16 flex bg-white">
                                    <img
                                        className="m-auto"
                                        height="75%"
                                        width="75%"
                                        src={MvSchoolLogo}
                                        alt="Muang Phayalae Wittaya School"
                                    />
                                </div>
                            </div>
                            <div className="w-8/12 text-left text-sm">
                                <p className="font-bold text-white">Muang Phayalae Wittaya School</p>
                                <p>Junior High School</p>
                                <p>2004-2006</p>
                                <p>Chaiyaphum</p>
                            </div>
                        </div>
                        <div className="inline-flex w-full p-2">
                            <div className="w-4/12 inline-flex">
                                <div className="m-auto rounded-full w-16 h-16 flex bg-white">
                                    <img
                                        className="m-auto"
                                        height="60%"
                                        width="60%"
                                        src={KwSchoolLogo}
                                        alt="Kanlayanawat School"
                                    />
                                </div>
                            </div>
                            <div className="w-8/12 text-left text-sm">
                                <p className="font-bold text-white">Kanlayanawat School</p>
                                <p>Senior High School</p>
                                <p>Science,Math</p>
                                <p>2007-2009</p>
                                <p>Khonkaen</p>
                            </div>
                        </div>
                        <div className="inline-flex w-full p-2">
                            <div className="w-4/12 inline-flex">
                                <div className="m-auto rounded-full w-16 h-16 flex bg-white">
                                    <img
                                        className="m-auto"
                                        height="45%"
                                        width="45%"
                                        src={KkuSchoolLogo}
                                        alt="Khonkaen University"
                                    />
                                </div>
                            </div>
                            <div className="w-8/12 text-left text-sm">
                                <p className="font-bold text-white">Khonkaen University</p>
                                <p>Bachelor Degrees</p>
                                <p>Computer Science</p>
                                <p>2010-2013</p>
                                <p>Khonkaen</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pt-10'>
                    {/* <FaLaptopCode className="m-auto" size="10vh" color="#bcbcbc" /> */}
                    <h2 className="text-5xl  py-10  font-semibold text-white">Work Experience</h2>
                    <div>
                        <div className="inline-flex w-full p-2">
                            <div className="w-5/12 md:w-6/12 inline-flex">
                                <div className="m-auto flex">
                                    <img
                                        className="m-auto p-2"
                                        width="85%"
                                        src={NectecLogo}
                                        alt="Nectec"
                                    />
                                </div>
                            </div>
                            <div className="w-7/12 md:w-6/12 text-left text-sm">
                                <p className="font-bold text-white">Apprentice.</p>
                                <p>Tech. for people with disabilities.</p>
                                <p>30/03/2013 - 30/05/2013</p>
                                <p>(2Months)</p>
                            </div>
                        </div>
                        <div className="inline-flex w-full p-2">
                            <div className="w-5/12 md:w-6/12 inline-flex">
                                <div className="m-auto flex">
                                    <img
                                        className="m-auto p-2"
                                        width="85%"
                                        src={CubeSofttechLogo}
                                        alt="Cube Softtech"
                                    />
                                </div>
                            </div>
                            <div className="w-7/12 md:w-6/12 text-left text-sm">
                                <p className="font-bold text-white">Full Stack Programmer.</p>
                                <p>Software House.</p>
                                <p>01/09/2014 - 30/09/2016</p>
                                <p>(2Years)</p>
                            </div>
                        </div>
                        <div className="inline-flex w-full p-2">
                            <div className="w-5/12 md:w-6/12 inline-flex">
                                <div className="m-auto flex">
                                    <img
                                        className="m-auto p-2"
                                        width="85%"
                                        src={AycapLogo}
                                        alt="Ayudhya Capital Services Company Limited" />
                                </div>
                            </div>
                            <div className="w-7/12 md:w-6/12 text-left text-sm">
                                <p className="font-bold text-white">Programmer/System Analyst.</p>
                                <p>Banking/Credit Card.</p>
                                <p>01/10/2016 - 05/05/2021</p>
                                <p>(4Years, 7Months, 5Days)</p>
                            </div>
                        </div>
                        <div className="inline-flex w-full p-2">
                            <div className="w-5/12 md:w-6/12 inline-flex">
                                <div className="m-auto flex">
                                    <img
                                        className="m-auto p-2"
                                        width="85%"
                                        src={KrungthaiLogo}
                                        alt="Krungthai Bank" />
                                </div>
                            </div>
                            <div className="w-7/12 md:w-6/12 text-left text-sm">
                                <p className="font-bold text-white">Full Stack Developer.</p>
                                <p>State Enterprise</p>
                                <p>05/05/2021 - 31/05/2021</p>
                                <p>(1Months)</p>
                            </div>
                        </div>
                        <div className="inline-flex w-full p-2">
                            <div className="w-5/12 md:w-6/12 inline-flex">
                                <div className="m-auto flex">
                                    <img
                                        className="m-auto p-2"
                                        width="55%"
                                        src={InfinitasByKrungthaiLogo}
                                        alt="Infinitas By Krungthai Bank" />
                                </div>
                            </div>
                            <div className="w-7/12 md:w-6/12 text-left text-sm">
                                <p className="font-bold text-white">Full Stack Developer.</p>
                                <p>State Enterprise</p>
                                <p>01/06/2021 - 31/09/2022</p>
                                <p>(1Year, 4Months)</p>
                            </div>
                        </div>
                        <div className="inline-flex w-full p-2">
                            <div className="w-5/12 md:w-6/12 inline-flex">
                                <div className="m-auto flex">
                                    <img
                                        className="m-auto p-2"
                                        width="75%"
                                        src={WedoByScgLogo}
                                        alt="Infinitas By Krungthai Bank" />
                                </div>
                            </div>
                            <div className="w-7/12 md:w-6/12 text-left text-sm">
                                <p className="font-bold text-white">Solution Technology Architect</p>
                                <p>Digital Office of SCG</p>
                                <p>01/09/2022 - Present</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}