import MainFormulaTwoPresent from './../../../../assets/products/farm/duean-jam/MainFormulaTwoPresent.png';
import ChickenEggshell from './../../../../assets/products/farm/duean-jam/ChickenEggshell.png';
import Rough from './../../../../assets/products/farm/duean-jam/Rough.png';
import Fine from './../../../../assets/products/farm/duean-jam/Fine.png';

export default function Home() {
    return (
        <div className="container pt-20 mx-auto text-[#a27345]">
            <h1 className='text-[5.5vw] md:text-[3.5vw] font-semibold'>เดือนแจ่ม สูตร 2 : ผสมเปลือกไข่ไก่บด</h1>
            <p className='text-[3vw] md:text-[1.5vw]'>ขนาด 1 กิโลกรัม ราคาถุงละ 35 บาท, ซื้อ 3 ถุง ราคา 100 บาทเท่านั้น</p>
            <div className="grid grid-cols-1 md:grid-cols-2 py-5">
                <div className='w-10/12 md:w-12/12 m-auto p-2'>
                    <img
                        className="m-auto"
                        height="100%"
                        width="100%"
                        src={MainFormulaTwoPresent}
                        alt="สูตร 2 : ผสมเปลือกไข่ไก่บด"
                    />
                </div>
                <div className='text-left inline-grid px-5 py-[3.5vh]'>
                    <div className='py-5 inline-flex'>
                        <img
                            className="rounded-full h-[85px] border-[#a27345] border-[2px]"
                            width={"85px"}
                            src={ChickenEggshell}
                            alt="เปลือกไข่บด"
                        />
                        <div className='pl-5'>
                            <h2 className='font-semibold text-2xl'>เปลือกไข่ไก่บด <span className='text-xs'>(100 กรัม)</span></h2>
                            <p className='text-[#a99d98]'>เสริมแคลเซียมให้พืช ช่วยเสริมสร้างเซลล์ และการแบ่งเซลล์ของพืช ทำให้พืชแข็งแรง</p>
                        </div>
                    </div>

                    <div className='py-5 inline-flex w-full'>
                        <img
                            className="rounded-full h-[85px] border-[#a27345] border-[2px]"
                            width={"85px"}
                            src={Rough}
                            alt="มูลไส้เดือนหยาบ"
                        />
                        <div className='pl-5'>
                            <h2 className='font-semibold text-2xl'>มูลไส้เดือนหยาบ <span className='text-xs'>(100 กรัม)</span></h2>
                            <p className='text-[#a99d98]'>ขนาด 3 มิลลิเมตร ระบายน้ำและอากาศได้ดี</p>
                        </div>
                    </div>
                    <div className='py-5 inline-flex w-full'>
                        <img
                            className="rounded-full h-[85px] border-[#a27345] border-[2px]"
                            width={"85px"}
                            src={Fine}
                            alt="มูลไส้เดือนละเอียด"
                        />
                        <div className='pl-5'>
                            <h2 className='font-semibold text-2xl'>มูลไส้เดือนละเอียด <span className='text-xs'>(800 กรัม)</span></h2>
                            <p>พิเศษ ! เพิ่มปริมาณเฉพาะช่วงเปิดตัวผลิตภัณฑ์</p>
                            <p className='text-[#a99d98]'>ขนาดเล็กมาก น้อยกว่า 3 มิลลิเมตร ทำให้พืชดูดซับสารอาหารได้ดีและเร็วขึ้น</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}