import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { ImOffice } from "@react-icons/all-files/im/ImOffice";


export default function Experience() {
    return (
        // <div>
        // {/* //     <VerticalTimeline>
        // //         <VerticalTimelineElement
        // //             className=""
        // //             contentStyle={{ background: '#fff', color: '#000' }}
        // //             contentArrowStyle={{ borderRight: '7px solid  #fff' }}
        // //             date="05-05-2011 - present"
        // //             iconStyle={{ background: 'rgb(0, 163, 227)', color: '#fff' }}
        // //             icon={<ImOffice />}
        // //         >
        // //             <h3 className="vertical-timeline-element-title">Infinitas by Krungthai</h3>
        // //             <h4 className="vertical-timeline-element-subtitle">Bank in Bank</h4>
        // //             <p>
        // //                 XXXX
        // //             </p>
        // //         </VerticalTimelineElement>
        // //         <VerticalTimelineElement
        // //             className=""
        // //             contentStyle={{ background: '#fff', color: '#000' }}
        // //             contentArrowStyle={{ borderRight: '7px solid  #fff' }}
        // //             date="05-05-2011 - present"
        // //             iconStyle={{ background: 'rgb(0, 163, 227)', color: '#fff' }}
        // //             icon={<ImOffice />}
        // //         >
        // //             <h3 className="vertical-timeline-element-title">Infinitas by Krungthai</h3>
        // //             <h4 className="vertical-timeline-element-subtitle">D-Market</h4>
        // //             <p>
        // //                 XXXX
        // //             </p>
        // //         </VerticalTimelineElement>
        // //         <VerticalTimelineElement
        // //             className=""
        // //             contentStyle={{ background: '#fff', color: '#000' }}
        // //             contentArrowStyle={{ borderRight: '7px solid  #fff' }}
        // //             date="05-05-2011 - present"
        // //             iconStyle={{ background: 'rgb(0, 163, 227)', color: '#fff' }}
        // //             icon={<ImOffice />}
        // //         >
        // //             <h3 className="vertical-timeline-element-title">Infinitas by Krungthai</h3>
        // //             <h4 className="vertical-timeline-element-subtitle">Thai Ruam Jai</h4>
        // //             <p>
        // //                 XXXX
        // //             </p>
        // //         </VerticalTimelineElement>
        // //         <VerticalTimelineElement
        // //             className=""
        // //             contentStyle={{ background: '#fff', color: '#000' }}
        // //             contentArrowStyle={{ borderRight: '7px solid  #fff' }}
        // //             date="05-05-2011 - present"
        // //             iconStyle={{ background: 'rgb(254, 213, 1)', color: '#fff' }}
        // //             icon={<ImOffice />}
        // //         >
        // //             <h3 className="vertical-timeline-element-title">Infinitas by Krungthai</h3>
        // //             <h4 className="vertical-timeline-element-subtitle">Thai Ruam Jai</h4>
        // //             <p>
        // //                 XXXX
        // //             </p>
        // //         </VerticalTimelineElement>
        // //         <VerticalTimelineElement
        // //             className=""
        // //             contentStyle={{ background: '#fff', color: '#000' }}
        // //             contentArrowStyle={{ borderRight: '7px solid  #fff' }}
        // //             date="05-05-2011 - present"
        // //             iconStyle={{ background: '#e70000', color: '#fff' }}
        // //             icon={<ImOffice />}
        // //         >
        // //             <h3 className="vertical-timeline-element-title">Infinitas by Krungthai</h3>
        // //             <h4 className="vertical-timeline-element-subtitle">Thai Ruam Jai</h4>
        // //             <p>
        // //                 XXXX
        // //             </p>
        // //         </VerticalTimelineElement>
        // //     </VerticalTimeline> */}
        // </div>
        <>
            <p>Underconstruction</p>
        </>
    )
}