import { Disclosure } from '@headlessui/react'
import { AiOutlineMenu } from '@react-icons/all-files/ai/AiOutlineMenu'
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose'
import FscPersonalLogo from '../../assets/personal/FscPersonalLogo.png'
import FscPersonalLogoText from '../../assets/personal/FscPersonalLogoText.png'

const navigation = [
    { name: 'Home', dataMenuanchor: "home", href: '#home' },
    { name: 'About Me', dataMenuanchor: "about-me", href: '#about-me' },
    { name: 'Projects', dataMenuanchor: "projects", href: '#projects' },
    { name: 'Skills', dataMenuanchor: "skills", href: '#skills' },
    { name: 'Contact Me', dataMenuanchor: "contact-me", href: '#contact-me' },
]

export default function MainMenu() {
    return (
        <Disclosure id="personal-main-menu" as="nav" className="bg-black top-0 fixed w-full z-10">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <AiOutlineClose className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <AiOutlineMenu className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="block lg:hidden h-10 w-auto"
                                        src={FscPersonalLogo}
                                        alt="FSC Online"
                                    />
                                    <img
                                        className="hidden lg:block h-10 w-auto"
                                        src={FscPersonalLogoText}
                                        alt="FSC Online"
                                    />
                                </div>
                                <div className="hidden sm:block sm:ml-6">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                data-menuanchor={item.dataMenuanchor}
                                                className={'px-3 py-2 rounded-md text-md font-medium'}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    data-menuanchor={item.dataMenuanchor}
                                    className={'block px-3 py-2 rounded-md text-base font-medium'}
                                >
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}