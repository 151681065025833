import PicProfile from '../../assets/personal/FscProfile2024.png'
import SlideTopDownGuide from '../../assets/commons/SlideTopDown.png'

export default function Home() {
    return (
        <>
            <div className='w-3/4 sm:w-6/12 md:w-4/12 lg:w-2/12 m-auto'>
                <img
                    className="rounded-full  block  w-full h-full"
                    src={PicProfile}
                    height={"100%"}
                    width={"100%"}
                    alt="FSC Online"
                />
            </div>
            <h1
                className="text-2xl py-1 md:text-7xl md:py-3 lg:text-5xl lg:py-3 "
            >Film Satapol Chatpahol</h1>
            <h2
                className="font-light text-sm py-1 md:text-4xl md:py-3 lg:text-2xl lg:py-3 "
            >Full-stack Software Developer , System Analyst</h2>
            <div className="slide-pointer-block">
            <img
                    className="slide-pointer"
                    src={SlideTopDownGuide}
                    alt="Slider"
                />
    </div>
             
        </>
    )
}