import { BrowserRouter, Route, Routes } from "react-router-dom";
import Personal from './components/personal/Personal';
import ProductDueanJam from './components/products/farm/duean-jam/DueanJam';
function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Personal />} />
          <Route path="/products/duean-jam/" element={<ProductDueanJam />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </div >
  );
}
export default App;