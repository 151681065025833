import './../../style/Personal.css';
import ReactFullpage from '@fullpage/react-fullpage';
import MainNavigator from "./MainNavigator";
import Home from './Home';
import AboutMe from './AboutMe';
import Projects from './Projects';
import ContactMe from './ContactMe';
import * as Constant from './../../Constant';
import Skills from './Skills';

export default function Personal() {
    return (
        <div>
            <MainNavigator />
            <ReactFullpage
                // [FSC] License
                licenseKey={Constant.REACT_FULLPAGE_LICENSE_KEY}

                // [FSC] Navigation
                menu='#personal-main-menu'
                lockAnchors={false}
                anchors={['home', 'about-me', 'projects', 'skills', 'contact-me']}
                navigation={false}
                navigationPosition='right'
                navigationTooltips={['home', 'about-me', 'projects', 'skills', 'contact-me']}
                showActiveTooltip={false}
                slidesNavigation={false}
                slidesNavPosition='bottom'

                // [FSC] Scrolling
                css3={true}
                scrollingSpeed={1000}
                autoScrolling={true}
                fitToSection={true}
                fitToSectionDelay={1000}
                scrollBar={false}
                easing='easeInOutCubic'
                easingcss3='ease'
                loopBottom={false}
                loopTop={false}
                loopHorizontal={true}
                continuousVertical={false}
                continuousHorizontal={false}
                scrollHorizontally={false}
                interlockedSlides={false}
                dragAndMove={false}
                offsetSections={false}
                resetSliders={false}
                fadingEffect={false}
                normalScrollElements='#element1, .element2'
                scrollOverflow={true}
                scrollOverflowReset={false}
                scrollOverflowOptions={null}
                touchSensitivity={15}
                bigSectionsDestination={null}

                // [FSC] Accessibility
                keyboardScrolling={true}
                animateAnchor={true}
                recordHistory={true}

                // [FSC] Design
                controlArrows={true}
                verticalCentered={true}
                sectionsColor={['', 'rgb(15 15 15)', 'rgb(227,227,227)', 'whitesmoke']}
                paddingTop='5vh'
                paddingBottom='5vh'
                fixedElements='.footer'
                responsiveWidth={0}
                responsiveHeight={0}
                responsiveSlides={false}
                parallax={false}
                parallaxOptions={{ type: 'reveal', percentage: 62, property: 'translate' }}
                dropEffect={false}
                dropEffectOptions={{ speed: 2300, color: '#F82F4D', zIndex: 9999 }}
                waterEffect={false}
                waterEffectOptions={{ animateContent: true, animateOnMouseMove: true }}
                cards={false}
                cardsOptions={{ perspective: 100, fadeContent: true, fadeBackground: true }}

                // [FSC] Custom selectors
                sectionSelector='.section'
                slideSelector='.slide'

                lazyLoading={true}

                // [FSC] Events
                onLeave={function (origin, destination, direction) { }}
                afterLoad={function (origin, destination, direction) { }}
                afterRender={function () { }}
                afterResize={function (width, height) { }}
                afterReBuild={function () { }}
                afterResponsive={function (isResponsive) { }}
                afterSlideLoad={function (section, origin, destination, direction) { }}
                onSlideLeave={function (section, origin, destination, direction) { }}

                render={({ state, fullpageApi }) => {
                    return (
                        <div id='personal-content'>
                            <div className='section home'>
                                <Home />
                            </div>
                            <div className="section about-me">
                                <AboutMe />
                            </div>
                            <div className="section projects">
                                <Projects />
                            </div>
                            <div className="section skills">
                                <Skills />
                            </div>
                            <div className="section contact-me">
                                <ContactMe />
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    )
}